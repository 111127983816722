import React, { useState, useEffect } from "react";
import { baseUrl } from "../requestUrl/index";
import SideBar from "../SideBar";
import TopBar from "../TopBar";
import axios from "axios";
import "./AllPages.css"
import { useNavigate } from "react-router";
import Button from '@mui/material/Button';
import Loader from "../Cards/loader";


function BusinessUserList() {
    const [id, setid] = useState([]);

    const navigate = useNavigate();

    const getBusinessUserList = async () => {

        const token = sessionStorage.getItem("token");

        await axios({
            method: "get",
            url: `${baseUrl}/api/admin/userlist`,
            headers: { Authorization: `jwt ${token}` },
        }).then((response) => {
            setid(response.data.data)
            if (response.status === 401) {
                navigate(`/`)
            }
        }).catch(err => {
            if (err.response.status === 401) {
                navigate(`/`)
            }
        })
    };

    useEffect(() => {

        getBusinessUserList();
    }, []);


    if (id && id.length > 0) {

        return (<>
            <div id="wrapper" width="100%">

                <SideBar />
                <div className="divmaldito">
                    <TopBar />

                    <div>
                        <h2>Business User List</h2>
                        <Loader />
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Business Name</th>
                                    <th>Status</th>
                                    <th>Profile Details</th></tr>
                            </thead>
                            <tbody>
                                {id.map(user => (
                                    <tr key={user.id}>
                                        <td>{user.id}</td>
                                        <td>{user.firstName}  {user.lastName} </td>
                                        <td>{user.email}</td>
                                        <td>{user.companyName}</td>
                                        <td>{user.isApprove == null ? "Pending" : user.isApprove == true ? "Approved" : "Rejected"}</td>
                                        <td><Button variant="contained" color="primary" onClick={() => navigate(`/Profile?id=${user.id}`)}>Profile</Button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div></div></>)
    }
    else {

        return (<div id="wrapper" width="100%">

            <SideBar />
            <div className="divmaldito">
                <TopBar />
                <div>
                    No Record Found
                </div>
            </div></div>

        )
    }

}

export default BusinessUserList;
import React, { useState, useEffect } from "react";
import SideBar from "../SideBar";
import TopBar from "../TopBar";
import axios from "axios";
import { baseUrl } from "../requestUrl/index";
import "./AllPages.css"
import { useNavigate } from "react-router";
import Loader from "../Cards/loader";

function PopularCategory() {
    const [id, setid] = useState([]);

    const getPopularCategory = async () => {
        const token = sessionStorage.getItem("token");

        await axios({
            method: "get",
            url: `${baseUrl}/api/admin/popularcategory`,
            headers: { Authorization: `jwt ${token}` },
        }).then((response) => {
            setid(response.data.data)

        });
    };

    const navigate = useNavigate();

    useEffect(() => {
        getPopularCategory();
    }, []);

    if (id && id.length > 0) {


        return (
            <>
                <div id="wrapper" width="100%">
                    <SideBar />
                    <div className="divmaldito">
                        <TopBar />
                        <div>

                            <h2>Popular Category</h2>
                            <Loader />
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Name</th>
                                        {/* <th>Website</th> */}
                                        <th>Description</th>
                                        <th>Count</th>
                                        {/* <th>Image</th> */}
                                    </tr>
                                </thead>

                                <tbody>
                                    {id.map(pop => (
                                        <tr key={pop.id}>
                                            <td>{pop.id}</td>
                                            <td>{pop.name}</td>
                                            <td>{pop.description}</td>
                                            <td>{pop.count}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    else {
        return (<div id="wrapper" width="100%">

            <SideBar />
            <div className="divmaldito">
                <TopBar />
                <div>
                    No Record Found
                </div>
            </div></div>

        )
    }
}

export default PopularCategory;

import React, { useState, useEffect } from "react";
import { baseUrl } from "../requestUrl/index";
import SideBar from "../SideBar";
import TopBar from "../TopBar";
import axios from "axios";
import "../Cards/AllPages.css";
import Loader from "../Cards/loader";

function Purchases() {
    const [product_id, setproduct_id] = useState([]);

    const getAllPurchases = async () => {

        const token = sessionStorage.getItem("token");

        await axios({
            method: "get",
            url: `${baseUrl}/api/admin/redirectionlist`,
            headers: { Authorization: `jwt ${token}` },
        }).then((response) => {
            console.log(response.data.data);
            setproduct_id(response.data.data)

        });
    };

    useEffect(() => {
        getAllPurchases();
    }, []);

    if (product_id && product_id.length > 0) {
        return (<>
            <div id="wrapper" width="100%">
                <SideBar />
                <div className="divmaldito">
                    <TopBar />
                    <div>
                        <h2>All Purchases</h2>
                        <Loader />
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Product Name</th>
                                    <th>Price</th>
                                    <th>Purchase Count</th>
                                    <th>Buy Now Url</th>
                                </tr>
                            </thead>
                            <tbody>
                                {product_id.map(purchase => (
                                    <tr key={purchase.id}>
                                        <td>{purchase.product_id.id}</td>
                                        <td>{purchase.product_id.name}</td>
                                        <td>{purchase.product_id.price}</td>
                                        <td>{purchase.purchaseCount}</td>
                                        <td>{purchase.product_id.buyNowUrl}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
        )
    }
    else {
        return (<div id="wrapper" width="100%">
            <SideBar />
            <div className="divmaldito">
                <TopBar />
                <div>
                    No Record Found
                </div>
            </div></div>

        )
    }
}

export default Purchases;
import React, { useState, useEffect } from "react";
import { baseUrl } from "../requestUrl/index";
import SideBar from "../SideBar";
import TopBar from "../TopBar";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router";
import "./AllPages.css";
import Button from '@mui/material/Button';
import Approval from "./Approval";

function Profile() {
    var param = new URLSearchParams(window.location.search);
    var id = param.get("id");
    const [user, setUser] = useState({});
    const [label, setLabel] = useState([
        "0",
        "First Name",
        "Last Name",
        "Email",
        "Mobile",
        "Address",
        "Website Url",
        "Business Name",
        "Business Type",
        "Role Name",
        "Mail",

    ]);


    const getProfileDetail = async () => {

        const token = sessionStorage.getItem("token");

        await axios({
            method: "get",
            url: `${baseUrl}/api/admin/getUser/${id}`,
            headers: { Authorization: `jwt ${token}` },
        }).then((response) => {
            let data = response.data.data;
            data.roleName = response.data.data.role_id.roleName
            delete data.role_id;
            delete data.status;
            delete data.profile;
            delete data.isApprove;
            data.mail = response.data.data.status == true ? "Verified" : "Not verified";

            setUser(data);
        });
    };

    const navigate = useNavigate();

    useEffect(() => {
        getProfileDetail();
    }, []);

    console.log(user)

    return (<>
        <div id="wrapper" width="100%">
            <SideBar />
            <div className="divmaldito">
                <TopBar />
                <div>
                    <h2>Profile Details</h2>
                    <div className="mb-5 pro">
                        <Container className="mt-3 ">

                            <Row>
                                <Col xs={12} md={8}>
                                    {user &&
                                        Object.keys(user).map((item, index) => {
                                            if (index == 0) {
                                                return;
                                            } else {
                                                return (
                                                    <Row>
                                                        <Col sm={3}>
                                                            <b>{label[index]}</b>
                                                        </Col>
                                                        <Col sm={4}>{user[item]}</Col>
                                                    </Row>
                                                );
                                            }
                                        })}

                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <Button variant="contained" className="reject" color="error" onClick={() => Approval(user.id, false, user.email, user.firstName) ? navigate(`/userlist`) : navigate(`/userlist`)}>
                        Reject
                    </Button>

                    <Button variant="contained" color="success" onClick={() => Approval(user.id, true, user.email, user.firstName) ? navigate(`/userlist`) : navigate(`/userlist`)} >
                        Approve
                    </Button>
                </div>
            </div>
        </div>
    </>
    )
}

export default Profile;
import "../../assets/css/app.css";
import anime from "../../assets/images/spinner.gif";
import React, { useState, useEffect } from "react";

function Loader() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <div className="spin-container">
      {loading ? (
        <div className="loader-container">
          <div className="spinneer">
            <img src={anime} alt="spinneer" />
          </div>
        </div>
      ) : (
        <>
        </>
      )
      }
    </div>
  )
}

export default Loader;

import React, { useState, useEffect } from "react";
import Login from "./Login";
import TopBar from "../TopBar";
import { Paper } from "@mui/material";
// import LoginLoader from "./LoginLoader";
const LoginPage = (props) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    
    return (
        <>
            <div className="mt-1  mb-5 container">
                <div className="row justify-content-center">
                    {" "}
                    <TopBar/>
                    <div className="col-6 center text-center ">
                       
                        <div>
                            <Login />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginPage;


import React, { useState, useEffect } from "react";
import foto from '../assets/images/81B29A.png';
import '../assets/css/colors.css';
// import logo from "../../assets/templogo.jpeg";
import flag from "../assets/images/flag.jpg";
import { useNavigate } from "react-router";
import "../components/utils/TopBar.css";
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    InputBase,
    Paper,
} from "@mui/material";


function TopBar(){
	const [isToken, setIsToken] = useState(false);

	useEffect(() => {
        let token = sessionStorage.getItem("token") || false;
        if (token == "false") {
            token = false;
        }
        if (token) {
            setIsToken(true);
        } else {
            setIsToken(false);
        }
    });

	const navigate = useNavigate();
    let LoginP = () => {
        navigate("/");
    };

	const Logout = () => {
        setIsToken(false);
        sessionStorage.setItem("token", false);
        // alert("User logged out successfully");
        navigate("/");
    };

	const isTokenThere = sessionStorage.getItem("isToken");
    return(
        <React.Fragment>
				{/*<!-- Topbar -->*/}
				<nav className="navbar navbar-expand navbar-light header-color topbar mb-4 static-top shadow topbarcss">

					{/*<!-- Sidebar Toggle (Topbar) -->*/}
					<button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
						<i className="fa fa-bars"></i>
					</button>

					{/*<!-- Topbar Navbar -->*/}
					<ul className="navbar-nav ml-auto">

						{/*<!-- Nav Item - Alerts -->*/}
						{/*<!-- <li className="nav-item dropdown no-arrow mx-1">
							<a className="nav-link dropdown-toggle" href="/" id="alertsDropdown">
								<i className="fas campanita fa-bell fa-fw"></i>
								{/*<!-- Counter - Alerts -->
								<span className="badge badge-danger badge-counter">3+</span>*/}
							{/*</a>
						</li>*/}

						{/*<!-- Nav Item - Messages -->*/}
						<li className="nav-item dropdown no-arrow mx-1">
							{/*<a className="nav-link dropdown-toggle" href="/" id="messagesDropdown">
								<i className="fas fa-envelope fa-fw"></i>
								{/*<!-- Counter - Messages -->*
								<span className="badge badge-danger badge-counter">7</span>
							</a>*/}
						</li>

						<div className="topbar-divider d-none d-sm-block"></div>

						{/*<!-- Nav Item - User Information -->*/}
						{isToken ? (
                        <Button
                            style={{ cursor: "pointer" }}
                            variant="outlined"
                            onClick={() => {
                                Logout();
                            }}
                        >
                            Logout
                        </Button>
                    ) : (
                        <Button
                            style={{ cursor: "pointer" }}
                            variant="outlined"
                            onClick={LoginP}
                        >
                            Admin Login
                        </Button>
                    )
                    }
                    {isTokenThere == "yes" && (
                        <div
                            className="flagimg"
                            onClick={() => {
                                navigate("/userlist");
                            }}
                        >
                            <img src={flag} alt="Flag" />
                        </div>
                    )}



						{/* <li className="nav-item dropdown no-arrow">
							<a className="nav-link dropdown-toggle" href="/" id="userDropdown">
								<span className="mr-2 d-none d-lg-inline texto-blanco small">
									Logout
								</span>
								<img className="img-profile rounded-circle" src={foto} alt="Logout" width="60"/>
							</a>
						</li> */}

					</ul>

				</nav>
				{/*<!-- End of Topbar -->*/}

        </React.Fragment>
    )
}
export default TopBar;

 import {
    Avatar,
    FormControlLabel,
    Grid,
    Paper,
    TextField,
    Checkbox,
    Button,
    Typography,
    Link,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React, { useState, useEffect } from "react";
import "./Login.css";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import logo from "../../assets/images/logo.png";
import axios from "axios";
import { setUserSession } from "../utils/common";
// import Popup from 'react-popup';
import { useNavigate } from "react-router";
import { baseUrl } from "../requestUrl";
import LogoutLoader from "../LoginPage/LoginLoader";

const Login = (props) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [Loading, setLoading] = useState("");
    const [formValues, setFormValues] = useState("");

    let navigate = useNavigate();

    const handleLogin = () => {
        
        axios
            .post(`${baseUrl}/api/businessUser/login`, {
                email: email,
                password: password,
            })
            .then((response) => {
                setLoading(false);
                setUserSession(response.data.token, response.data.user);
                navigate("/userlist");
            })
            .catch((error) => {
                setLoading(false);
                alert("invalid username or password");
            });
    };

    const Signup = () => {
        navigate("/SignupPage");
    };

    return (
        <>
       
        <div className="grid-center" align="center">
            <Card sx={{ minWidth: 275 }}>
                <CardContent>
                    <Grid align="center">
                        {/* <Avatar className='avatar'><LockOutlinedIcon/></Avatar> */}
                        <div className="headlogo">
                            <img src={logo} alt="Logo" />
                        </div>
                    </Grid>
                    <TextField
                        label="Username"
                        placeholder="Enter Username"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="mt-3 login-field "
                    />
                    <br></br>
                    <TextField
                        label="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Enter Password"
                        required
                        className="mt-3 login-field "
                    />
                    <br></br>
                    <FormControlLabel
                        control={
                            <Checkbox
                                className="remember"
                                inputProps={{
                                    "aria-label": "uncontrolled-checkbox",
                                }}
                            />
                        }
                        label="Remember me "
                    />
                    <br></br>
                    {error && <div className="error">{error}</div>}
                    <Button
                        variant="contained"
                        value={Loading ? "Loading..." : "Login"}
                        disabled={Loading}
                        onClick={handleLogin}
                        // style="background-color:black"
                        // color="primary"
                        className="loginbutton"
                        
                    >
                        Login
                    </Button>

                    <div className="under">
                        <Typography className="mt-2 ">
                            {/* <a href="#" color="secondary" className="under"></a> */}
                            <b className="forgot-hover"> Forgot Password?</b>
                        </Typography>
                    </div>
                </CardContent>
            </Card>
        </div>
   </> );
};

export default Login;

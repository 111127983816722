import { baseUrl } from "../requestUrl/index";
import axios from "axios";
import { ToastSuccess } from "../Toast/index";

async function Approval(id, status, email, firstName) {
    const token = sessionStorage.getItem("token");
    await axios({
        method: "put",
        url: `${baseUrl}/api/admin/approval`,
        headers: { Authorization: `jwt ${token}` },
        data: {
            id: id,
            status: status,
            email: email,
            firstName: firstName
        },
    }).then((response) => {
        if (response.status == 200) {
            ToastSuccess("data updated successfully")
            return true
        }
        else {
            return true
        }
    });
};

export default Approval;
import React, { useState, useEffect } from "react";
import SideBar from './SideBar';
import '../assets/css/app.css'
import LoginPage from "./LoginPage/LoginPage";
import Footer from './Footer/Footer';
import TopBar from './TopBar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import BusinessUserList from './Cards/BusinessUserList';
import Profile from './Cards/Profile';
import PopularCategory from './Cards/PopularCategory';
import Purchases from './Cards/Purchases';
import Loader from "./Cards/loader";
import "../assets/css/app.css";
// import anime from "C:/Users/DELL/Desktop/MadeNAmerica/dashboard/src/assets/images/animated-star-image-0155.gif";
import Dashboard from "../components/Dashboard/Dashboard";


function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    // <div className="spin-container">
    //   {loading ? (
    //     <div className="loader-container">
    //       <div className="spinner">
    //         <img src={anime} alt="spinner" />
    //       </div>
    //     </div>
    //   ) : (
        <>
          <React.Fragment>
            <Routes >
              <Route exact path="/" element={<LoginPage />} />
              {/* <Route path='*' element={<NotFound />} /> */}
              <Route exact path="/dashboard" element={<><div id="wrapper" width="100%">

                <SideBar />
                <div className="divmaldito">
                  <TopBar /> <Dashboard /></div></div></>}> </Route>
              <Route path="popularcategory" element={<PopularCategory />}> </Route>
              <Route path="userlist" element={<BusinessUserList />}> </Route>
              <Route path="Profile" element={<Profile />} />
              <Route path="redirectionlist" element={<Purchases />} />
            </Routes>
            <Footer />
          </React.Fragment>
        </>)}

    // </div>

  // );
// };

export default App;

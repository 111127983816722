import React from 'react';
import ContentRowTop from '../Dashboard/ContentRowTop';

function Dashboard() {
    return (
        <React.Fragment>
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <ContentRowTop />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Dashboard;